<template>
  <div>
    A
  </div>
</template>

<script>

export default {
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
};
</script>

<style>
</style>